import React, { useState } from 'react';
import Table from 'Components/Table';
import { ColumnDef } from '@tanstack/react-table';
import Button from 'Components/Button';
import AddEditUserModal from './AddEditUserModal';
import { deleteUser } from 'Services/api';
import ConfirmDeleteModal from 'Components/ConfirmDeleteModal';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'Store/loadingSlice';
import { toast } from 'react-toastify';
import { UserResponse } from 'Types';

const Users: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUserData, setSelectedUserData] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const dispatch = useDispatch();

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      const result = await deleteUser(deleteId);
      if (result.success) {
        setRefresh(true);
        toast.success('User deleted successfully!');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      console.error(`Error: ${error}`);
      if (error.response.status === 409) {
        toast.warning('Primary admin cannot be deleted');
      } else {
        toast.error('Something went wrong!');
      }
    }
    dispatch(stopLoading());
    setShowDeleteModal(false);
    setDeleteId('');
  };

  const columns = React.useMemo<ColumnDef<UserResponse>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <span>Business Partner</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 45,
      },
      {
        accessorKey: 'email',
        header: () => <span>Champion</span>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 45,
      },
      {
        header: () => <div className="w-full text-center">Action</div>,
        accessorKey: 'action',
        cell: ({ row }) => (
          <div className="flex justify-center">
            <button
              onClick={() => {
                setIsModalOpen(true);
                setSelectedUserData(row.original);
              }}
              className="text-xs text-primary hover:text-primary-500 cursor-pointer"
            >
              Edit
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row.original.user_id);
              }}
              className="text-xs text-invalid hover:text-invalid-500 cursor-pointer ml-1"
            >
              Delete
            </button>
          </div>
        ),
        footer: (props) => props.column.id,
        size: 10,
      },
    ],
    [],
  );

  return (
    <div className="min-h-screen flex w-full bg-gray-100 p-5">
      <div className="flex flex-col w-full bg-white rounded-lg">
        <div className="flex justify-between w-full items-center mt-3">
          <h1 className="text-base font-medium pl-5">User List</h1>
          <Button
            text="Add User"
            className="h-10 w-[200px] mr-5"
            type="button"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
        <Table
          url="/user"
          columns={columns}
          refresh={refresh}
          setRefresh={(value) => setRefresh(value)}
        />
        <AddEditUserModal
          userData={selectedUserData}
          isOpen={isModalOpen}
          doRefresh={() => setRefresh(true)}
          onModalClose={() => {
            setSelectedUserData(null);
            setIsModalOpen(false);
          }}
        />
        <ConfirmDeleteModal
          modalTitle="Delete User"
          modalText="Are you sure you want to delete the user?"
          isOpen={showDeleteModal}
          onModalClose={() => setShowDeleteModal(false)}
          onConfirm={onDeleteConfirm}
        />
      </div>
    </div>
  );
};

export default Users;
