import axios from 'axios';
import { toast } from 'react-toastify';
import { logout } from 'Store/authSlice';
import store from 'Store/store';
import {
  PaginatedResponse,
  LoginResponse,
  AddCompanyResponse,
  CommonResponse,
  AddUserResponse,
  CompanySettingsResponse,
  ProjectRequestType,
  CompanySettingsRequest,
  GraphResponse,
} from 'Types';

// Create an Axios instance with default settings
const api = axios.create({
  // @TODO add env variable
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // Request timeout in milliseconds
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
  },
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logout);
      toast.warning('Session Expired!');
    }
    return Promise.reject(error);
  },
);

export const fetchData = async (url: string, page = 0) => {
  try {
    const response = await api.get<PaginatedResponse>(url);
    return response.data;
  } catch (error) {
    throw new Error(`Error while logging in`);
  }
};

export const login = async (
  email: string,
  password: string,
): Promise<LoginResponse> => {
  try {
    const response = await api.post<LoginResponse>(`/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error while logging in`);
  }
};

export const addCompany = async (companyData: {
  name: string;
  email: string;
  company_name: string;
}): Promise<AddCompanyResponse> => {
  const response = await api.post<AddCompanyResponse>(`/company`, companyData);
  return {
    ...response.data,
    success: response.data.success || response.status === 201,
  };
};

export const updateCompany = async (
  companyData: {
    name: string;
    email: string;
    company_name: string;
  },
  companyId: string,
): Promise<AddCompanyResponse> => {
  const response = await api.put<AddCompanyResponse>(
    `/company/${companyId}`,
    companyData,
  );
  return {
    ...response.data,
    success: response.data.success || response.status === 200,
  };
};

export const deleteCompany = async (
  id: string,
): Promise<CommonResponse<any>> => {
  const response = await api.delete<CommonResponse<any>>(`/company/${id}`);
  return response.data;
};

export const addUser = async (userData: {
  name: string;
  email: string;
}): Promise<AddUserResponse> => {
  const response = await api.post<AddUserResponse>(`/user`, userData);
  return {
    ...response.data,
    success: response.data.success || response.status === 201,
  };
};

export const updateUser = async (
  userData: {
    name: string;
    email: string;
  },
  id: string,
): Promise<AddUserResponse> => {
  const response = await api.put<AddUserResponse>(`/user/${id}`, userData);
  return {
    ...response.data,
    success: response.data.success || response.status === 200,
  };
};

export const deleteUser = async (id: string): Promise<CommonResponse<any>> => {
  const response = await api.delete<CommonResponse<any>>(`/user/${id}`);
  return response.data;
};

export const getCompanySettings =
  async (): Promise<CompanySettingsResponse> => {
    const response = await api.get<CompanySettingsResponse>(`/settings`);
    return response.data;
  };

export const updateCompanySettings = async (
  request: CompanySettingsRequest,
): Promise<CompanySettingsResponse> => {
  const response = await api.put<CompanySettingsResponse>(`/settings`, request);
  return response.data;
};

export const addProject = async (
  projectData: ProjectRequestType,
): Promise<CommonResponse<any>> => {
  const response = await api.post<CommonResponse<any>>(`/project`, projectData);
  return {
    ...response.data,
    success: response.data.success || response.status === 200,
  };
};
export const updateProject = async (
  projectData: ProjectRequestType,
  id: string,
): Promise<CommonResponse<any>> => {
  const response = await api.put<CommonResponse<any>>(
    `/project/${id}`,
    projectData,
  );
  return {
    ...response.data,
    success: response.data.success || response.status === 200,
  };
};

export const getProject = async (
  id: string,
): Promise<CommonResponse<ProjectRequestType>> => {
  const response = await api.get<CommonResponse<ProjectRequestType>>(
    `/project/${id}`,
  );
  return {
    ...response.data,
    success: response.data.success || response.status === 200,
  };
};

export const deleteProject = async (
  id: string,
): Promise<CommonResponse<any>> => {
  const response = await api.delete<CommonResponse<any>>(`/project/${id}`);
  return response.data;
};

export const getGraphData = async (): Promise<GraphResponse> => {
  const response = await api.get<GraphResponse>(`/graph`);
  return response.data;
};

export const resetPassword = async (resetPasswordData: {
  current_password: string;
  new_password: string;
}): Promise<CommonResponse<number>> => {
  const response = await api.post<CommonResponse<number>>(
    `/password_reset`,
    resetPasswordData,
  );
  return {
    ...response.data,
    data: response.status,
    success: response.data.success || response.status === 200,
  };
};
